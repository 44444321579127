@import '../../styles/variables.scss';
@import "../../styles/mixins.scss";

.sidebar{
  display: none;
  width: 303px;
  height: 100%;
  box-sizing: border-box;
  padding: $margin-md;
  background: $blue;
  @media screen and (min-width : $screen-md) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  @media screen and (min-width : 1220px) {
    width: 393px;
  }

  .header{
    width: 100%;
    a{
      display: flex;
      z-index: 1;
      align-items: center;
      text-decoration: none;
      min-width: 100%;
      padding: $margin-md 0;
      border-bottom: 1px solid rgba(255,255,255,.2);
    }

    p{
      margin: 0;
      font-size: $medium;
    }

    a:first-child{
      color: $white;
      p{
        color: $white;
        margin-left: $margin-sm;
      }
    }
    a:last-child{
      justify-content: space-between;
      color: rgba(255,255,255,.5);
      p{
        color: rgba(255,255,255,.5);
      }
    }
  }

  .content{
    text-align: center;
    h2{
      font-size: 32px;
      color: $light-blue;
      text-transform: uppercase;
      margin-bottom: $margin-md;
    }
    p{
      color : $white;
      font-size: $subtitle;
      line-height: 30px;
      margin-bottom: $margin-md;
    }

    .tag {
      @include tag;
      height: auto;
      min-height: 30px;
      line-height: 25px;
      padding: 5px 10px;
      text-align: center;
    }

  }

  a{
    z-index: 1;
    text-decoration: none;
    width: 100%;

    button{
      display: flex !important;
      width: 100%;
      flex-direction: column !important;;
      box-shadow: 0px 9px 17px rgba(1, 151, 218, 0.25) !important;
      outline: none;
      padding: $margin-md !important;;
      font-size: $subtitle !important;;
      height: auto !important;
  
      img{
        margin-bottom: $margin-md;
        max-width: 40px;
        width: 100%;
      }
      span{
        display: block;
        width: 100%;
        max-width: 205px;
      }
    }
  
    
  }
}