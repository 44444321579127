@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.ranking-teams{
  position: relative;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 2;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .advices{
    margin: $margin-md 0 $margin-sm 0;
    @media screen and (min-width: $screen-sm) {
      margin: $margin-md 0 $margin-sm 0;
    }
  }

  a{
    text-decoration: none;
  }

  .container{
    width: 100%;
    @media screen and (min-width: $screen-sm) {
      max-width: 700px;
      margin: 0 auto;
    }

    .header{
      padding: $margin-md $margin-md $margin-sm $margin-md;
  
      @media screen and (min-width: $screen-sm) {
        padding: $margin-md $margin-lg $margin-sm $margin-lg;
      }

      h2{
        font-size: $medium;
        color: $light-blue;
        text-transform: uppercase;
        margin-bottom: 0;
      }
  
      .container-button{
        display: flex;
        justify-content: space-between;
        max-width: 700px;
        margin: 0 auto;
        
        .btnSort{
          display: flex;
          flex-direction: column;
          font-size: 20px;
          svg:first-child{
            margin-bottom: -3px;
          }
        }
      }
  
    }
  }

}