@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.photo-teams{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 100%;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  z-index: 2;
  scrollbar-width: none;

  &.disabled {
    opacity: 0.5;
    transform: scale(0.9);
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .header{
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: $margin-md $margin-md 0 $margin-md;

    @media screen and (min-width: $screen-sm) {
      padding: $margin-lg $margin-lg 0 $margin-lg;
    }

    .advices{
      margin-bottom: $margin-md;
    }
  }

  a{
    text-decoration: none;
  }

  .container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    width: 100%;
    @media screen and (min-width: $screen-sm) {
      max-width: 700px;
      margin: 0 auto;
    }

    .header-list{
      padding: $margin-md $margin-md $margin-md $margin-md;
  
      @media screen and (min-width: $screen-sm) {
        padding: $margin-md $margin-lg $margin-md $margin-lg;
      }
      
    }

    .container-ranking{
      flex: 1;
    }

  }

  .link{
    padding: 0 $margin-md $margin-md $margin-md;

    button{
      display: block;
      width: 100%;
      max-width: 450px;
      margin: 0 auto;
    }
  
    @media screen and (min-width: $screen-sm) {
      padding: 0 $margin-lg $margin-md $margin-lg;
    }
  }

  .link-home{
    margin-top: $margin-md;
    padding: 0 $margin-md $margin-md $margin-md;

    >a{
      display: flex;
      align-items: center;
      text-decoration: none;
      svg {
        position: relative;
        top: -1px;
        color: $text-color;
      }
      p{
        font-family: 'Montserrat-Bold';
        font-size: $medium;
        margin: 0 0 0 $margin-sm;
        color: $text-color;
      }
    }
  }

}