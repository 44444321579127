@import "../../../styles/variables.scss";

.team {
  background: $white;
  border-radius: 8px;
  box-shadow: 0px 30px 30px rgba(57, 57, 57, 0.1);
  padding: $margin-sm $margin-md $margin-sm $margin-md; 
  box-sizing: border-box;

  &.editable-serie{
    .container>div:first-child {
      width: calc(100% - 124px);
    }
  }

  &.editable-quiz{
    .container>div:first-child {
      width: calc(100% - 87px);
    }
  }


  .container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 36px;
    > div:first-child {
      width: 100%;
      
    }
    > div:last-child {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .name {
      font-family: "MontSerrat-Bold";
      font-weight: normal;
      font-size: $medium;
      color: $title-color;
      margin: 0;
      max-width: 100%;
      width: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .containerInput{

      .score,
      .serie-score {
        display: inline-flex;
        width: 65px;
        align-items: center;
        justify-content: center;
        background: rgba(0, 168, 232, 0.07);
        border: 1px solid #00a8e8;
        height: 40px;
        border-radius: 12px;
        padding: 0 $margin-sm;
        color: $text-grey;
  
        &.disabled{
          border-color: transparent;
          background-color: transparent;
          input{
            color: $light-blue;
          }
        }
  
        input {
          display: inline-block;
          text-align: right;
          border: none;
          font-family: "MontSerrat-Bold";
          font-weight: normal;
          font-size: $medium;
          color: $text-color;
          padding: 0;
          background: transparent;
          width: 100%;
          margin-right: 5px;
          -moz-appearance: textfield;
          outline: none;
  
          :disabled {
            background: transparent;
          }
  
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }

      .score+.btnEdit,
      .serie-score+.btnEdit{
        position: absolute;
        right: 0;
        background-color: red;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        transform: translate(-50%, 0);
        transition: all .3s ease-in-out;
        cursor: pointer;
        &.hide{
          transform: translate(-50%, 0) scale(0);
        }
      }

      .score.disabled,
      .serie-score.disabled{
        cursor: pointer;
        input{
          pointer-events: none;
        }
      }

      .icon{
        position: relative;
        min-height: 32px;
        min-width: 32px;
        margin-right: 5px;
        svg,
        img{
          position: absolute;
          top: 50%; left: 50%;
          transform: translate(-50%,-50%);
          max-width: 32px;
        }
      }

    }

    // .serie-score {
      // &.serie-1 {
      //   background-color: rgba(248, 72, 131, 0.3);
      //   border: 1px solid rgba(248, 72, 131, 1);
      // }

      // &.serie-2 {
      //   background-color: rgba(63, 196, 181, 0.3);
      //   border: 1px solid rgba(63, 196, 181, 1);
      // }

      // &.serie-3{
      //   background-color: rgba(252, 153, 53, 0.3);
      //   border: 1px solid rgba(252, 153, 53, 1);
      // }
  
      // &.serie-4{
      //   background-color: rgba(136, 76, 212, 0.3);
      //   border: 1px solid rgba(136, 76, 212, 1);
      // }
    // }

  }
}
