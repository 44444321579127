@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

// MUI MOBILE DATEPICKER
.toolbar{
  p{
    font-family: "MontSerrat-SemiBold";
    font-size: $subtitle;
    font-weight: bold;
    margin: 0;
    color: white;
    &.small{
      font-size: $regular;
      +p{
        margin-top: 5px
      }
    }
  }
}

.container-input{

  label {
    @include label;
  }

  input{
    @include input;
  }

}
