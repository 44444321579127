@import "../../styles/variables.scss";

.container{
  input{
    height: auto !important;
    background-color: none !important;
    border-color: transparent !important;
  }

  .createoption{
    font-family: 'Montserrat-SemiBold';
    // .label{
    //   color: $light-blue;
    // }
    .newValue{
      color: $light-blue;
    }
  }

}