@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.home {
  position: relative;
  height: 100%;
  z-index: 2;
  padding: 0 0 80px 0;
  box-sizing: border-box;

  h3 {
    font-size: $medium;
  }

  .tag {
    @include tag;
    height: auto;
    min-height: 30px;
    line-height: 20px;
    padding: 5px 10px;
    text-align: center;
    max-width: 200px;
    text-align: right;
    margin-left: $margin-xs;
  }

  .container {
    max-width: 1200px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: $margin-sm $margin-md $margin-sm $margin-md;
    overflow-y: auto;

    @media screen and (min-width: $screen-md) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .container-teams {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      display: none;
      max-height: 90%;
      overflow-y: hidden;

      h3 {
        color: $text-color;
        font-size: $title;
        padding: 0 $margin-md;
        margin-bottom: $margin-md;
      }

      .anchor {
        font-family: "Montserrat-Bold";
        font-size: $medium;
        color: $light-blue;
        padding: 0 $margin-md 0 $margin-md;
        text-decoration: underline;
        cursor: pointer;
      }

      .scrollableContainer {
        &::-webkit-scrollbar {
          display: none;
        }
        overflow-y: auto;
        padding-bottom: $margin-lg;
        .containerForm {
          border-top: 1px dashed rgba(32, 32, 32, 0.1);
          margin: $margin-md 0 0 0;
          padding: $margin-md $margin-sm 0 $margin-sm;
          p {
            color: $light-blue;
            padding: 0 $margin-sm;
          }
        }
      }

      @media screen and (min-width: $screen-md) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        width: 350px;
        max-height: calc(100% - #{$margin-md});
        padding: $margin-md 0 0 0;
        margin-left: $margin-lg;
        box-shadow: 0px 30px 60px rgba(57, 57, 57, 0.1);
        border-radius: 8px;
        background: $white;
      }
    }

    .header-desktop {
      display: none;

      img{
        max-height: 70px;
        margin-right: $margin-xs;
        margin-left: -5px;
      }

      @media screen and (min-width: $screen-md) {
        display: flex;
        margin-bottom: $margin-md;
        align-items: center;

        h2 {
          color: $text-color;
          font-size: 40px;
          margin-bottom: 0;
        }
      }
    }

    .header-mobile {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: $margin-md;

      @media screen and (min-width: $screen-md) {
        display: none;
      }

      .date {
        text-transform: capitalize;
      }

      h2 {
        text-transform: uppercase;
        font-size: $subtitle;
        color: $light-blue;
        margin-top: 3px;
      }

      p {
        margin-bottom: 0;
      }
    }

    .container-button {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: $margin-md;
      height: auto;
      > a,
      .indice {
        width: calc(50% - 5px);
        align-self: stretch;
        height: auto;
        text-decoration: none;
        &:nth-child(n + 3) {
          margin-top: $margin-sm;
          display: flex;
          @media screen and (min-width: $screen-md) {
            display: none;
          }
        }
      }

      > a button {
        height: 100%;
        width: 100%;
      }

      .indice {
        flex-direction: column;
        align-self: stretch;
        align-items: flex-start;
        justify-content: flex-start;
        .btnHeader {
          background-color: #fcdb17;
          width: 100%;
          height: 45px;
          margin: 0;
          padding: 0 $margin-xs;
          box-sizing: border-box;
          border-radius: 13px 13px 0 0;
          @media screen and (min-width: $screen-xs) {
            padding: 0 $margin-sm;
          }
          > span:first-child {
            position: relative;
            display: inline-block;
            height: 45px;
            font-size: 18px;
            line-height: 45px;
            margin-right: 5px;
            @media screen and (min-width: $screen-xs) {
              font-size: 28px;
            }
          }

          > span:last-child {
            font-family: "MontSerrat-Regular";
            position: relative;
            top: -1px;
            text-transform: uppercase;
            font-size: $regular;
            @media screen and (min-width: $screen-xs) {
              font-size: $medium;
              top: -3px;
            }
          }
        }
        .text-indice {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          height: calc(100% - 45px);
          width: 100%;
        }
        p {
          margin: 0;
          border-top: none;
        }
      }

      button {
        position: relative;
        flex-direction: column;
        align-self: stretch;
        padding: 0;
        background: $white;
        color: $text-color;
        text-transform: none;

        .countTeams{
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $light-blue;
          margin-left: 0;
          height: 34px;
          width: 34px;
          border-radius: 50%;
          transform: translate(-30%,-15%);
          p{
            margin: 0;
            padding: 0;
            color: $white;
            font-size: 18px;
          }
        }

        p {
          font-family: "MontSerrat-Bold";
          padding: $margin-sm $margin-md;
          margin-bottom: 0;
          border-top: 1px dashed rgba(32, 32, 32, 0.1);
          width: 100%;
          box-sizing: border-box;
        }

        .thin {
          display: inline-block;
          margin-top: $margin-xs;
          font-family: "MontSerrat-Regular" !important;
          font-weight: normal;
        }

        img {
          display: block;
          margin: $margin-sm 0 $margin-sm 0;
          max-height: 50px;
          @media screen and (min-height: 700px) {
            max-height: inherit;
            margin: $margin-md 0 $margin-sm 0;
          }
        }
      }
    }

    .container-advices {
      margin-bottom: $margin-md;
      @media screen and (min-width: $screen-md) {
        margin-bottom: $margin-lg;
      }
    }

    .container-toggle {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin: $margin-md 0;
      padding-top: $margin-md;
      border-top: 1px dashed rgba(32, 32, 32, 0.1);
      @media screen and (min-width: $screen-md) {
        padding-top: 0;
        border-top: none;
      }
      p {
        font-family: "MontSerrat-SemiBold";
        margin-right: $margin-md;
      }
    }

    .container-bonus-desktop {
      display: none;
      @media screen and (min-width: $screen-md) {
        display: block;
      }
      .bonus {
        display: flex;
        background: $white;
        box-shadow: 0px 30px 60px rgba(57, 57, 57, 0.1);
        border-radius: 8px;
        // padding: $margin-md;
        > div {
          background-color: #fcdb17;
          padding: $margin-md 15px;
          border-radius: 8px 0 0 8px;
          > p {
            font-family: "Montserrat-SemiBold";
            font-size: 25px;
            margin: 0;
          }
        }

        > p {
          font-family: "MontSerrat-Bold";
          font-weight: normal;
          font-size: $medium;
          margin: 0;
          padding: $margin-md;
        }
      }
    }
  }
}
