@import "../../styles/variables.scss";

.loader {
  border-radius:50%;
  animation:s3 1s infinite linear;

  &.md{
    width:50px;
    height:50px;
    -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  }

  &.sm{
    width:25px;
    height:25px;
    -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
  }

  &.white{
    background:conic-gradient(#0000 10%,$white);
  }
  
  &.blue{
    background:conic-gradient(#0000 10%,$light-blue);
  }

  &:not(.white):not(.blue){
    background:conic-gradient(#0000 10%,$blue);
  }

}
@keyframes s3 {to{transform: rotate(1turn)}}