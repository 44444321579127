@use "sass:list";
@import "./variables.scss";

@mixin tag () {
  display: inline-block;
  font-family: "MontSerrat-Bold";
  font-weight: normal;
  text-transform: uppercase;
  padding: 0 $margin-sm;
  box-sizing: border-box;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  background-color: rgba(0, 168, 232, .1);
  color: $light-blue;
  margin: 0;
}

@mixin remove-highlight(){
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin remove-tap-highlight(){
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

@mixin basic-input(){
  padding: 15px;
  font-family: 'MontSerrat-Bold';
  font-size: $regular;
  color: $white;
  width: 100%;
  box-sizing: border-box;
  border-radius: 14px;
  background: rgba(255, 255, 255, 0.40);
  border: none;
  outline: none;
  margin-bottom: $margin-sm;
  &::placeholder{
    font-family: 'MontSerrat-Bold';
    font-size: $regular;
    color: $white;
  }
}

@mixin textarea(){
  width: 100%;
  background: rgba(130, 170, 228, 0.1);
  border: 1px solid rgba(47, 48, 78, 0.06);
  box-sizing: border-box;
  border-radius: 14px;
  padding: $margin-sm $margin-sm;
  font-family: "MontSerrat-Regular";
  font-size: $regular;
  outline: none;

  &::placeholder{
    font-family: "MontSerrat-Regular";
    color : #637F8E;
    font-size: $regular;
  }
}

@mixin label{
  display: inline-block;
  font-family: "MontSerrat-SemiBold";
  color: $text-color;
  font-size: $regular;
  margin-bottom: $margin-sm;
}

@mixin input(){
  width: 100%;
  height: 49px;
  background: rgba(130, 170, 228, 0.1);
  border: 1px solid rgba(47, 48, 78, 0.06);
  box-sizing: border-box;
  border-radius: 14px;
  padding: $margin-sm $margin-sm;
  font-family: "MontSerrat-SemiBold";
  color: $text-color;
  font-size: $regular;
  outline: none;

  &::placeholder{
    font-family: "MontSerrat-Regular";
    color : #637F8E;
    font-size: $regular;
  }
}

// $colors: rgb(248, 72, 131), rgb(63, 196, 181), rgb(252, 153, 53), rgb(136, 76, 212), rgba(47, 79, 191),
// rgb(255, 136, 112), rgb(135, 44, 205), rgb(135, 44, 205), rgb(15, 173, 194), rgb(53, 173, 102), rgb(154, 40, 128);

// @function getColor2($index, $alpha: 1) {
//   $color: rgba(248, 72, 131, $alpha);
//   @if list.nth($colors, $index) {
//     $color: rgba( list.nth($colors, $index), $alpha);
//   }@else {
//     $color: rgba(248, 72, 131, $alpha);
//   }
//   @return $color;
// }

@function getColor($serie, $alpha: 1) {
  $color: rgba(248, 72, 131, $alpha);
  @if $serie == serie-1 {
    $color: rgba(248, 72, 131, $alpha);
  }@else if $serie == serie-2 {
    $color: rgba(63, 196, 181, $alpha);
  }@else if $serie == serie-3 {
    $color: rgba(252, 153, 53, $alpha);
  }@else if $serie == serie-4 {
    $color: rgba(136, 76, 212, $alpha);
  }@else if $serie == serie-5 {
    $color: rgba(47, 79, 191, $alpha);
  }@else if $serie == serie-6 {
    $color: rgba(255, 136, 112, $alpha);
  }@else if $serie == serie-7 {
    $color: rgba(135, 44, 205, $alpha);
  }@else if $serie == serie-8 {
    $color: rgba(15, 173, 194, $alpha);
  }@else if $serie == serie-9 {
    $color: rgba(53, 173, 102, $alpha);
  }@else if $serie == serie-10 {
    $color: rgba(154, 40, 128, $alpha);
  }
  @return $color;
}

@function getGradient($serie) {
  $gradient: linear-gradient(91.14deg, #ff4380 0%, #ff729f 97.85%);
  @if $serie == serie-1 {
    $gradient: linear-gradient(91.14deg, #ff4380 0%, #ff729f 97.85%);
  }@else if $serie == serie-2 {
    $gradient: linear-gradient(91.14deg, #57e2c1 0%, #2cb1b1 97.85%);
  }@else if $serie == serie-3 {
    $gradient: linear-gradient(91.14deg, #ffb932 0%, #fd7d36 97.85%);
  }@else if $serie == serie-4 {
    $gradient: linear-gradient(91.14deg, #9457e2 0%, #5315a2 97.85%);
  }@else if $serie == serie-5 {
    $gradient: linear-gradient(90deg, #7D9AFF 0%, #3050C0 100%);
  }@else if $serie == serie-6 {
    $gradient: linear-gradient(270deg, #FF896F 0%, #FA8181 100%);
  }@else if $serie == serie-7 {
    $gradient: linear-gradient(94.39deg, #C08BEA 3.66%, #6F05C2 96.57%);
  }@else if $serie == serie-8 {
    $gradient: linear-gradient(270deg, #04A5BB 0%, #74F7FF 100%);
  }@else if $serie == serie-9 {
    $gradient: linear-gradient(270deg, #1A994D 0%, #7EE3A7 100%);
  }@else if $serie == serie-10 {
    $gradient: linear-gradient(94.39deg, #C4109B 3.66%, #843472 96.57%);
  }
  @return $gradient;
}

@mixin colorSerie($property: color) {
  @for $i from 1 through 10 {
    $serie: serie-#{$i};
    @if $property == color {
      &.serie-#{$i} { 
        color: getColor($serie, 1);
       }
    }@else if $property == colorAndBackground {
      &.serie-#{$i} { 
        color: getColor($serie, 1);
        background-color: getColor($serie, 0.3);
       }
    }@else if $property == backgroundGradient {
      &.serie-#{$i} { 
        background: getGradient($serie);
       }
    }
  }

}