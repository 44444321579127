@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.review {
  position: relative;
  height: 100%;
  z-index: 2;
  padding: 0 0 0px 0;
  box-sizing: border-box;

  .header-desktop {
    display: none;
    @media screen and (min-width: $screen-md) {
      display: flex;
      justify-content: space-between;
      margin: 0 $margin-md 0 $margin-md;
      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $light-blue;
        svg {
          position: relative;
          top: -4px;
          margin-right: $margin-sm;
          color: $light-blue;
        }
      }
    }
  }

  .container {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: $margin-md 0 $margin-lg 0;
    overflow-y: auto;
  }

  .nav-series {

    &.bottom{
      margin-top: $margin-md;
      ul{
        padding-bottom: $margin-lg;
      }
    }

    .title {
      margin: 0 $margin-md 0 $margin-md;
      @media screen and (min-width: $screen-sm) {
        margin: 0 $margin-lg 0 $margin-lg;
      }
      h3 {
        margin-bottom: 0;
      }
    }

    ul {
      display: flex;
      flex-wrap: nowrap;
      margin: -30px 0 -30px 0;
      width: 100%;
      overflow-x: auto;
      scrollbar-width: none;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        display: none;
      }

      padding: 50px $margin-md 50px $margin-md;
      @media screen and (min-width: $screen-sm) {
        padding: 50px $margin-lg 50px $margin-lg;
      }

      li {
        display: inline-block;
        list-style: none;
        &:not(:last-child) {
          margin-right: $margin-sm;
        }

        button {
          box-shadow: 0px 20px 60px rgba(57, 57, 57, 0.25);
        }

        &:not(.active) button {
          background: $white;
          color: $title-color;
        }

        &.active {
          button.button-bonus {
            background: linear-gradient(180deg, #00a8e8 0%, #0081c9 100%);
          }

          button.serie-1 {
            background: linear-gradient(91.14deg, #ff4380 0%, #ff729f 97.85%);
          }

          button.serie-2 {
            background: linear-gradient(91.14deg, #57e2c1 0%, #2cb1b1 97.85%);
          }

          button.serie-3 {
            background: linear-gradient(91.14deg, #ffb932 0%, #fd7d36 97.85%);
          }

          button.serie-4 {
            background: linear-gradient(91.14deg, #9457e2 0%, #5315a2 97.85%);
          }
        }
      }
    }
  }

  .advices-mobile {
    margin: 0 $margin-md $margin-md $margin-md;
    @media screen and (min-width: $screen-sm) {
      display: none;
    }
  }

  .container-bonus {
    ul {
      height: 100%;
      box-sizing: border-box;
      padding: 0;
      box-sizing: border-box;
      margin: 0;

      .bonus {
        position: relative;
        list-style-type: none;
        background: $white;
        padding: $margin-md;
        margin-bottom: $margin-md;
        border-radius: 8px;
        box-shadow: 0px 30px 60px rgba(57, 57, 57, 0.1);

        p {
          font-family: "MontSerrat-Bold";
          font-size: $regular;
          color: $text-color;
          margin-bottom: 0;
        }

        .tag {
          @include tag;
          margin-bottom: $margin-sm;
        }
      }
    }

    .bonus-answer {
      padding: $margin-md;
      border-radius: 8px;
      box-shadow: 0px 30px 60px rgba(57, 57, 57, 0.1);
      background: linear-gradient(180deg, #00a8e8 0%, #0081c9 100%);
      p {
        font-family: "MontSerrat-Bold";
        font-size: $regular;
        color: $white;
        margin-bottom: 0;
      }
    }
  }

  .list {
    padding: 0 $margin-md;

    @media screen and (min-width: $screen-sm) {
      padding: 0 $margin-lg;
    }

    > ul {
      height: 100%;
      box-sizing: border-box;
      padding: 0;

      box-sizing: border-box;
      margin: 0;

      li {
        display: block;
        list-style: none;
        &:not(:last-child) {
          margin-bottom: $margin-md;
        }
      }
    }
  }
}
