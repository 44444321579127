@font-face {
	font-family: "Montserrat-Bold";
	src: url("../assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-SemiBold";
	src: url("../assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-Regular";
	src: url("../assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

// COLORS

$light-blue: #00A8E8;
$blue: #2D2E5C;
$title-color: #4B4C76;
$text-color: #2F304E;
$text-grey: rgba(47, 48, 78, 0.5);
$white: #FFFFFF;

// METRICS
$screen-xs: 468px;
$screen-sm: 768px;
$screen-md: 1024px;

$margin-xs: 5px;
$margin-sm: 10px;
$margin-md: 20px;
$margin-lg: 30px;

// FONT SIZE
$title: 24px;
$subtitle: 20px;
$medium: 16px;
$regular: 14px;
$small: 12px;