@import "../../styles/variables.scss";

.container-slider{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all .6s ease-in-out;
  
  &.hide{
    opacity: 0;
    transform: translate(0,100px);
  }

  .univers{
    font-family: "MontSerrat-Bold";
    font-size: $small;
    text-transform: uppercase;
    color: $white;
    position: absolute;
    z-index: 3;
    bottom: calc(50% + 245px);
    left: calc(50% - 150px);
    transition: opacity 0.6s ease-in-out;
    pointer-events: none;
    width: 300px;
    text-align: center;

    span{
      font-size: $medium;
    }

    @media screen and (max-width : $screen-xs) and (max-height : 700px) {
      // top: $margin-lg;
      bottom: calc(100% - 45px);
    }

    &.hide{
      opacity: 0;
    }

    @media screen and (min-width : $screen-xs) {
      text-align: left;
      top: $margin-sm;
      left: $margin-md;
      font-size: $medium;
    }

    @media screen and (min-width : $screen-sm) {
      min-width: 200px;
    }

    @media screen and (min-width : $screen-md) {
      display: none;
      justify-content: flex-start;
      min-width: 50%;
    }

  }

  >img{
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translate(0,-50%);
    cursor: pointer;
    &.prev{
      left: calc(50% - 250px);
    }
    &.next{
      right: calc(50% - 250px);
    }
  }
}

.slider{
  display: flex;
  height: 100%;
  padding: 0; 
  scrollbar-width: none;
  box-sizing: border-box;
  will-change: transform;
  
  &::-webkit-scrollbar {
    display: none;
  }

  .slide{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-width: 100%;

    @media screen and (min-width : 700px) {
      display: flex;
      justify-content: flex-start;
      min-width: 50%;

      &.short{
        min-width: auto;
      }

      &.large{
        min-width: calc(100% - 300px);
      }

      @media screen and (min-width : 700px) {
        &:first-child{
          padding-left: calc(50% - 150px);
        }

        &:last-child{
          padding-right: 150px;
        }
        
      }

    }

  }
}