@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.bottombar{
  position: absolute;
  display: flex;
  left:0;
  right:0;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  z-index: 3;
  height: 80px;
  cursor: pointer;
  border-radius: 15px 15px 0 0;
  background: linear-gradient(180deg, #00A8E8 0%, #0081C9 100%);
  box-shadow: 0px -10px 30px rgba(57, 57, 57, 0.2);

  >div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    >a{
      text-decoration: none;
      text-align: center;
      @include remove-tap-highlight;
      &.active p{
        opacity: 1;
        color : $light-blue;
      }
    }

    p{
      font-family: "Montserrat-Bold";
      font-size: $small;
      margin: 0;
      color : $blue;
      opacity: 0.5;
      margin-top: 5px;
    }

  }

  .play-button{
    font-size: 0;
    background-color: transparent;
    @include remove-tap-highlight;
    a{
      display: flex;
      align-items: center;
      justify-content: center;
      transform-origin: center;
    }
    p{
      position: relative;
      font-size: 18px;
      display: inline-block;
      color: white;
      opacity: 1;
      margin: 0;
    }
    .icon{
      border: solid 2px $white;
      margin-left: $margin-sm;
      padding: 12px;
      border-radius: 50%;
      @media screen and (min-width: $screen-xs) {
        margin-left: $margin-md;
      }
      svg{
        position: relative;
        left: 4px;
        width: 30px;
        height: 30px;
        color: $white;
      }
    }
  }
}