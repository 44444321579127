@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.teams {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  max-width: 100%;
  width: 100%;
  margin-bottom: -80px;

  .noScroll{
    overflow: visible;
  }

  &:not(.noScroll){
    overflow-y: auto;
    height: 100%;
  }

  ul {
    max-width: 100%;
    min-height: 100%;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    padding: 0 $margin-md 80px $margin-md;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      list-style: none;
      margin: 0 0 $margin-sm 0;
      max-width: 100%;
      &.itemTeam {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        max-width: 100%;
        .index {
          min-width: 24px;
          font-family: "MontSerrat-Bold";
          font-size: 14px;
          margin: 0;
          color: #2f304e;
        }
        &.withIndex > div {
          width: calc(100% - 24px);
        }
        &:not(.withIndex) > div {
          width: 100%;
        }
      }
    }
  }
}
