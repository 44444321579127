@import "../../styles/variables.scss";

.help {
  position: relative;
  height: 100%;
  z-index: 2;
  padding: 0 0 80px 0;
  box-sizing: border-box;

  @media screen and (min-width: $screen-md) {
    margin: $margin-md 60px;
  }
  
  .container {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: $margin-md 0 $margin-lg 0;
    overflow-y: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .row{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      .left{
        text-align: left;
        width: 100%;
        @media screen and (min-width: $screen-md) {
          max-width: 50%;
        }

        .contact .container >span p:last-child{
          text-align: left;
        }

      }

      .right{
        padding-left: $margin-lg;
        box-sizing: border-box;
        @media screen and (min-width: $screen-md) {
          max-width: 47%;
        }
        .installation{
          background-color: $white;
          box-shadow: 0px 30px 60px rgba(57, 57, 57, 0.1);
          border-radius: 8px;
          padding: $margin-md $margin-lg $margin-sm $margin-lg;

          h2{
            font-family: "MontSerrat-Bold";
            font-weight: normal;
            font-size: $title;
            margin-bottom: 0;
          }

        }
      }

    }
  
  }
}

.menu{
  padding: 0;
  margin: 0 auto;

  @media screen and (min-width: $screen-md) {
    margin: 0;
  }

  li{
    list-style-type: none;
    text-align: left;
    padding: $margin-md;

    @media screen and (min-width: $screen-sm) {
      padding: $margin-md $margin-lg;
    }

    @media screen and (min-width: $screen-md) {
      padding: $margin-md 0;
    }

    &:not(:last-child){
      border-bottom: solid 1px rgba(47, 48, 78, 0.1);
    }

    a{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: 'MontSerrat-Bold';
      font-weight: normal;
      font-size: $subtitle;
      color: $title-color;
      text-decoration: none;
    }

  }
}

.home-help,
.faq,
.rules,
.contact,
.installation{
  position: relative;
  min-height: 100%;

  .loader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .header-desktop {
    display: none;
    @media screen and (min-width: $screen-md) {
      display: flex;
      justify-content: space-between;
      margin: 0 0 $margin-sm 0;
      width: 100%;
      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $light-blue;
        svg {
          position: relative;
          top: -4px;
          margin-right: $margin-sm;
          color: $light-blue;
        }
      }
    }
  }
}

.contact{
  .container{
    padding: $margin-md;

    @media screen and (min-width: $screen-sm) {
      padding: $margin-md $margin-lg;
    }

    @media screen and (min-width: $screen-md) {
      padding: $margin-md 0;
    }

    >span p:last-child{
      font-family: 'MontSerrat-Bold';
      text-align: center;
    }

  }
}

.installation,
.faq,
.rules{
  ul{
    padding: 0;
    margin: 0 auto;

    @media screen and (min-width: $screen-md) {
      margin: 0;
    }

    li{
      list-style-type: none;
      text-align: left;
      padding: $margin-md;

      @media screen and (min-width: $screen-sm) {
        padding: $margin-md $margin-lg;
      }

      @media screen and (min-width: $screen-md) {
        padding: $margin-md 0;
      }

      &:not(:last-child){
        border-bottom: solid 1px rgba(47, 48, 78, 0.1);
      }

      h4{
        font-family: 'MontSerrat-Bold';
        font-weight: normal;
        font-size: $medium;
        color: $title-color;
      }

      p{
        font-family: 'MontSerrat-Regular';
        font-weight: normal;
        font-size: $regular;
        color: $text-color;
        &:last-child{
          margin-bottom: 0;
        }
      }

    }
  }
}


