@import '../../styles/variables.scss';

.container-textarea{
  margin-bottom: $margin-md;
  width: 100%;
  max-width: 500px;

  .count-characters{
    text-align: right;
    font-size: 12px;
    margin: 0;
    padding: 0;
  }

  textarea{
    background: rgba(130, 170, 228, 0.1);
    border: 1px solid rgba(47, 48, 78, 0.06);
    border-radius: 14px;
    font-family: "MontSerrat-Bold";
    color: $text-color;
    font-size: $regular;
    resize: none;
    width: 100%;
    padding: $margin-sm $margin-md;
    box-sizing: border-box;
    outline: none;

    &::placeholder{
      font-family: "MontSerrat-Regular";
      color: $text-grey;
      font-size: $regular;
    }
  }

}
