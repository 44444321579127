@import "../../styles/variables.scss";

.conseil {
  padding: $margin-sm;
  border-radius: 7px;
  text-align: left;
  background: $blue;

  p{
    font-family: "MontSerrat-Regular";
    font-size: $regular;
    color: $white;
    margin: 0 0 5px 0;
  }

  &.primary {
    ul li:before{
      content: '';
      display: block;
      position: absolute;
      top: 3px;
      left: 0px;
      width: 18px;
      height: 18px;
      background-image: url("../../assets/images/icons/checkbox-advice.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  ul {
    padding: 0 0 0 0;
    margin: 0;
    li {
      position: relative;
      font-family: "MontSerrat-Regular";
      font-size: $regular;
      line-height: 24px;
      color: $white;
      list-style: none;
      padding-left: 25px;
    }
  }
}
