@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.card {
  position: relative;
  z-index: 2;
  background: $white;
  border-radius: 8px;
  box-shadow: 0px 30px 60px rgba(57, 57, 57, 0.1);

  &.withReload {
    // padding: $margin-md $margin-sm $margin-md $margin-lg;

    @media screen and (max-width: $screen-xs) {
      .header {
        padding: $margin-md $margin-sm $margin-sm $margin-md;
      }
      .content {
        padding: 0 $margin-sm $margin-md $margin-md;
      }
    }

    @media screen and (min-width: $screen-xs) {
      // padding: $margin-md $margin-sm $margin-md $margin-lg;
      .header {
        padding: $margin-md $margin-sm $margin-sm $margin-lg;
      }
      .content {
        padding: 0 $margin-sm $margin-md $margin-lg;
      }
    }
  }

  &:not(.withReload) {
    .header {
      padding: $margin-md $margin-sm $margin-sm $margin-sm;
    }
    .content {
      padding: 0 $margin-sm $margin-md $margin-sm;
    }
  }

  .reload {
    position: absolute;
    z-index: 3;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-40%, -50%);
    background: white;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    @include remove-tap-highlight();

    > * {
      pointer-events: none;
    }

    @include colorSerie(color);

    > svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scaleX(-1) rotate(25deg);
    }
  }

  .header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 0 $margin-sm 0;
    border-bottom: 1px dashed rgba(32, 32, 32, 0.1);
    p {
      margin: 0;
      font-size: $small;
      line-height: $small;
    }

    .rubrique,
    .point {
      padding: $margin-sm;
      box-sizing: border-box;
      border-radius: 15px;
    }

    .index {
      font-family: "MontSerrat-Bold";
      text-transform: uppercase;
      color: $text-grey;
    }

    .rubrique {
      font-family: "MontSerrat-Bold";
      text-transform: uppercase;
      margin-right: 5px;

      // @media screen and (max-width : $screen-xs) {
      //   width: 100%;
      // }

      @include colorSerie(colorAndBackground);
    }

    .container-left {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-right: $margin-sm;

      > span {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-right: 5px;
        // .rubrique{
        //   width: 100%;
        // }
      }

      .difficulty {
        color: $text-grey;
        white-space: nowrap;
        padding: 8px 0 8px 3px;
      }
    }

    .container-right {
      display: flex;
      align-items: center;
      justify-content: center;

      .point {
        height: 30px;
        @for $i from 1 through 10 {
          $serie: serie-#{$i};
          &.serie-#{$i} {
            color: getColor($serie, 1);
            box-shadow: 0px 4px 20px 2px getColor($serie, 0.3);
          }
        }
      }
    }
  }

  .content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 0 $margin-sm $margin-md $margin-sm;

    > span {
      flex: 1;
    }

    .answer-only {
      .media {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: $margin-md;

        .mediaAnswer {
          margin-top: 0;
        }

        p {
          font-size: $regular;
          font-family: "Montserrat-Bold";
          width: 100%;
          max-width: 200px;
          margin: 0;
          @include colorSerie(color);
        }
      }

      p {
        &.bold {
          font-family: "MontSerrat-Bold";
          font-size: $medium;
          color: $text-color;
          margin-bottom: $margin-xs;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .container-question {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      p {
        flex: 1;
      }
    }

    .container-answer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;

      &:not(.withMediaRemoteAnswer):not(.withMediaAnswer) {
        margin-top: $margin-md;
      }

      &.withMediaRemoteAnswer {
        border-top: solid 1px #eceef1;
        padding-top: $margin-sm;
        margin-top: $margin-md;
      }

      &.withMediaAnswer {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding-top: $margin-sm;
        margin-top: $margin-md;
        border-top: solid 1px #eceef1;
        div:first-child {
          flex: 1;
        }
        > button {
          // transform-origin: top right;
          transform: scale(0.7);
        }
      }

      p {
        flex: 1;
      }
    }

    .controls-media {
      display: flex;
      width: 100%;
      &:not(.mediaAnswer) {
        margin: $margin-sm 0;
      }
      &.mediaAnswer {
        margin: $margin-sm 0 0 0;
        button {
          // 39 x 30
          height: 30px;
          width: 39px;
          padding: 0;
          border-radius: 9px;

          > img {
            transform: scale(0.7) translateY(-2px);
          }

          > svg {
            transform: scale(0.7);
          }
        }
      }
      button {
        position: relative;
        // margin-bottom: $margin-xs;
        margin: 0 $margin-sm 0 0;
      }
    }

    button {
      font-family: "MontSerrat-Bold";
      font-size: $medium;
      height: 44px;
      border-radius: 13px;
      text-align: center;
      padding: 0 $margin-md;
      border: none;
      cursor: pointer;
      transition: all 0.4s ease-in-out;
      margin-left: $margin-sm;

      img {
        position: relative;
        top: 1px;
      }

      @for $i from 1 through 10 {
        $serie: serie-#{$i};
        &.serie-#{$i} { 
          background: getGradient($serie);
          box-shadow: 0px 4px 20px 2px getColor($serie, 0.3);
         }
      }
    }
  }

  .content .question,
  .content .comments,
  .content .answer {
    font-family: "MontSerrat-Regular";
    font-size: $small;
    color: $text-grey;
    &.bold {
      font-family: "MontSerrat-Bold";
      font-size: $regular;
      color: $text-color;
    }
  }

  .content .answer-only .comments{
    position: relative;
    margin-bottom: $margin-sm;
    padding-bottom: $margin-sm;
    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      left: -10px;
      width: calc(100% + 20px);
      border-top: 1px dashed rgba(32, 32, 32, 0.1);
    }
  }
  
  .content:not(.answer-only) .answer {
    font-family: "MontSerrat-Bold";
  }

  .content .answer {
    margin-bottom: 0;
    box-sizing: border-box;
  }
}
