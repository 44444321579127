@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.container-textarea{
  margin: 5px 0px 20px 0px;

  .container{
    width: 100%;
    min-width: 250px;

    .textarea{
      @include textarea();
      border-bottom: none;
    }

    .count-characters{
      @include label();
      display: block;
      font-size: $small;
      margin: 0;
      text-align: right;
      padding: 0;
    }

  }





}
