@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.container-input-format-number {

  label {
    @include label;
  }

  .input {
    @include input;
  }
  
}
