@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.navigation {
  position: relative;
  width: 100%;
  height: auto;
  background-color: $blue;
  padding: $margin-sm 0 $margin-sm 0;
  box-sizing: border-box;

  @media screen and (min-width: $screen-md) {
    position: relative;
    display: flex;
    align-items: center;
    background-color: transparent;
    padding: $margin-md 0;
  }

  .toggle {
    position: absolute;
    z-index: 2;
    left: 50%;
    bottom: -24px;
    transform: translate(-50%, 0);
    color: $white;
    font-size: $title;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    @include remove-tap-highlight;

    .open {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      top: 10px;
      background-color: $blue;
      padding: 10px 15px 4px $margin-md;
      border-radius: 0 0 19px 19px;
      white-space: nowrap;
      > span {
        position: relative;
        top: -3px;
        font-family: "Montserrat-SemiBold";
        font-size: $medium;
      }
      svg {
        position: relative;
        top: -1px;
        margin-left: $margin-sm;
      }
    }

    &:not(.show){
      pointer-events: none;
    }
    
    &.show {
      cursor: pointer;
      opacity: 1;
    }

    @media screen and (min-width: $screen-md) {
      display: none;
    }

    > svg {
      position: absolute;
      left: 44px;
      transform: translate(-50%, -5px);
    }
  }

  .info-serie {
    position: relative;
    z-index: 2;
    display: none;
    opacity: 1;
    transition: all 0.4s ease-in-out;
    padding-left: $margin-md;
    &.hide {
      opacity: 0;
      pointer-events: none;
    }
    @media screen and (min-width: $screen-md) {
      position: absolute;
      display: block;
      max-width: 225px;
      p {
        font-family: "MontSerrat-Bold";
        font-size: $medium;
        line-height: $medium;
        color: white;
        margin: 0 0 5px 0;
        &.univers {
          text-transform: uppercase;
        }
      }
    }
  }

  .slider button {
    font-family: "MontSerrat-Bold";
    font-weight: normal;
    white-space: nowrap;
    font-size: $medium;
    height: 46px;
    border-radius: 13px;
    text-align: center;
    border: none;
    cursor: pointer;
    transition: none;
    // transition: all 0.4s ease-in-out;
    box-shadow: none;
    padding: 0;

    &:not(.bonus) {
      width: 46px;
    }

    &.bonus {
      padding: 0 $margin-md;
    }

    &:not(.active):not(.disabled) {
      background: white;
      color: $text-color;
    }

    &.active,
    &.disabled {
      color: white;
    }

    &.disabled {
      background: rgba(255, 255, 255, 0.2);
    }


    @media screen and (min-width: $screen-md) {
      &.disabled:not(:last-child) {
        background: rgba(255, 255, 255, 0.2);
      }

      &.disabled:last-child {
        color: $blue;
        background: rgba(255, 255, 255, 0.5);
      }
    }

    &.active {
      @media screen and (min-width: $screen-md) {
        background: linear-gradient(280.72deg, #2f304e 0%, #3b3d74 102.83%);
      }

      &.serie-end{
        background: linear-gradient(131.32deg, #00A8E8 9.51%, #0236BC 91.65%);
      }

      @media screen and (max-width: $screen-md) {
        @include colorSerie(backgroundGradient);
      }
    }
  }
}

.navigation {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .container-slider {
    width: 100%;
    transform: all 0.6s ease-in-out;
    @media screen and (min-width: $screen-md) {
      mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 25%,
        rgba(0, 0, 0, 1) 40%,
        rgba(0, 0, 0, 1) 60%,
        rgba(0, 0, 0, 0) 90%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }

  .container-slider {
    width: 100%;
    &.hide-on-mobile {
      @media screen and (max-width: $screen-md) {
        display: none;
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    &.open .container-quickview {
      opacity: 1;
    }
  }
  &:not(.open) .container-quickview {
    opacity: 0;
  }

  .container-quickview {
    position: relative;
    align-self: stretch;
    height: 100%;
    overflow: visible;
    box-sizing: border-box;
    padding: 0 $margin-sm 0 $margin-sm;
    scrollbar-width: none;
    transition: all 0.6s ease-in-out;
    // opacity: 0 !important;

    @media screen and (min-width: $screen-md) {
      opacity: 0;
    }

    @media screen and (min-width: $screen-xs) {
      padding: 0 $margin-md 0 $margin-md;
    }
  }

  .container-slider .slider {
    position: relative;
    display: flex;
    width: 100%;
    padding: 20px 0;
    margin: -20px 0;
    // box-sizing: border-box;
    scrollbar-width: none;
    will-change: transform;

    // @media screen and (min-width: $screen-md) {
    //   align-items: center;
    //   justify-content: flex-end;
    //   right: 30px;
    // }

    @media screen and (min-width: 1185px) {
      align-items: center;
      justify-content: flex-start;
      margin-right: 0px;
      padding: 20px 0;
      margin: -20px 0;
      right: 0px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .slide {
      display: flex;
      // flex: 1;
      align-items: center;
      justify-content: center;

      &:not(:last-child) {
        margin: 0 $margin-md 0 0;
      }

      &:first-child {
        justify-content: flex-end;
        min-width: calc(50% + 72px);
        @media screen and (min-width: $screen-md) {
          min-width: calc(50% + 47px);
        }
      }

      &:last-child {
        justify-content: flex-start;
        min-width: calc(50% + 52px);
      }
    }

    @media screen and (max-width: $screen-md) {
      // .slide:first-child {
      //   min-width: calc(50% + 23px);
      //   width: calc(50% + 23px);
      //   justify-content: flex-end;
      //   &.serie-1 {
      //     min-width: calc(50% + 52px);
      //     width: calc(50% + 52px);
      //   }
      // }

      // .slide:not(:first-child) {
      //   box-sizing: border-box;
      //   padding-left: $margin-md;
      // }
    }
  }

  .link-home {
    position: absolute;
    z-index: 10;
    top: 30px;
    right: 20px;
  }
}
