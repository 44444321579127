@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.quickview {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow: hidden;

  >.header {
    display: flex;
    justify-content: space-between;
    margin: $margin-sm 0 $margin-md 0;
    text-align: center;
    p {
      font-family: "MontSerrat-Bold";
      font-size: $medium;
      color: $white;
      margin: 0;
      text-align: center;
      width: 100%;
    }
  }

  .list-bonus,
  .list {
    position: relative;
    align-self: stretch;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      pointer-events: none;
      z-index: 2;
      display: block;
      bottom: -1px;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% + 20px);
      height: 60px;
      background: linear-gradient(
        180deg,
        rgba(48, 49, 77, 0) 0%,
        #2d2e5c 97%,
        #2d2e5c 100%
      );
      pointer-events: none;
    }
  }

  .list > ul {
    height: 100%;
    overflow-y: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    padding: 0 0 $margin-lg 0;
    box-sizing: border-box;
    margin: 0;

    li {
      display: block;
      list-style: none;
      &:not(:last-child) {
        margin-bottom: $margin-md;
      }
    }
  }

  .bonus {
    position: relative;
    list-style-type: none;
    background: $white;
    padding: $margin-sm;
    margin-bottom: $margin-sm;
    border-radius: 8px;
    box-shadow: 0px 30px 60px rgba(57, 57, 57, 0.1);

    .header{
      position: relative;
      margin: 0 0 $margin-sm 0;
      &:after{
        content: '';
        position: absolute;
        bottom: 0;
        left: -10px;
        width: calc(100% + 20px);
        border-top: 1px dashed rgba(32, 32, 32, 0.1);
      }
      .tag {
        @include tag;
        margin-bottom: $margin-sm;

        @for $i from 1 through 10 {
          $serie: serie-#{$i};
          &.serie-#{$i} { 
            color: getColor($serie, 1);
            background-color: getColor($serie, 0.3);
            span:first-child{
              border-right: solid 1px getColor($serie, 0.5);
            }
          }
        }

        >span:first-child{
          position: relative;
          display: inline-block;
          height: 30px;
          font-size: 20px;
          line-height: 30px;
          padding-right: 8px;
          margin-right: 8px;
        }

        >span:last-child{
          position: relative;
          top: -2px;
        }

      }
    }

    .bonus-answer{
      margin-bottom: $margin-sm;
      @include colorSerie(color);
    }

    .indice{
      font-size: $medium;
      margin-bottom: $margin-sm;
    }

    .comments {
      position: relative;
      font-family: "MontSerrat-Regular";
      font-size: $small;
      color: $text-grey;
      margin-bottom: $margin-sm;
      padding-bottom: $margin-sm;
      &:after{
        content: '';
        position: absolute;
        bottom: 0;
        left: -10px;
        width: calc(100% + 20px);
        border-top: 1px dashed rgba(32, 32, 32, 0.1);
      }
    }

    p {
      font-family: "MontSerrat-Bold";
      font-size: $regular;
      color: $text-color;
      margin-bottom: 0;
    }
  }

}
