@import "../../../styles/variables.scss";

.video{
  background-color: $white;
  box-shadow: 0px 4px 20px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  font-size: 0;
  width: 100%;
  iframe{
    width: 100%;
  }
}