.container-input-password{
  position: relative;

  label{
    font-size: 14px;
  }

  &.inline{
    display: flex;
    justify-content: space-between;
    align-items: center;
    label{
      margin-right: 10px;
    }
  }

  &:not(.inline){
    label+.container-input{
      margin-top: 10px;
    }
  }

}

.container-input{
  position: relative;
  width: 100%;

  .container-icon{
    display: block;
    position: absolute;
    top:0;
    right:0;
    height: 36px;
    width: 36px;
    cursor: pointer;
    >svg{
      position: absolute;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
    }
  }

}

.container-input .input-password{
  display: block;
  padding: 10px 36px 10px 10px;
  border: none;
  outline: none;
  background-color: white;
  border-radius: 8px;
  width: 100%;
  min-width: 250px;
  box-sizing: border-box;
  font-size: 12px;
  border: solid 1px rgba(230,230,230,1);

  &::-ms-reveal,
  &::-ms-clear{
    display: none;
  }

  // HIDE KEY ON SAFARI
  &::-webkit-credentials-auto-fill-button {
    margin: 0;
    width: 0;
    background-color: transparent;
  }

  &:focus{
    box-shadow: 0 0 0 1px #009973;
  }
  &::placeholder {
    font-size: 12px;
    color : #8c8c8c;
  }
}
