@import "../../styles/variables.scss";

.ranking{
  padding: 0 $margin-md $margin-md $margin-md;

  @media screen and (min-width: $screen-sm) {
    padding: 0 $margin-lg $margin-md $margin-lg;
  }

  ul{
    padding: 0;
    margin: 0;
  }
}