@import "../../styles/variables.scss";

.feedback{
  position: relative;
  height: 100%;
  z-index: 2;
  box-sizing: border-box; 

  .greetings{
    max-width: 1200px;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color : $light-blue;
    box-sizing: border-box; 
    padding: $margin-md;
    
    svg{
      margin-bottom: $margin-md;
    }

    h3{
      color: $title-color;
      font-size: $title;
      text-align: center;
    }

  }

  .container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
    height: 100%;
    margin : 0 auto;
    box-sizing: border-box;
    overflow-y: auto;
    padding: $margin-sm $margin-md 0 $margin-md;

    >div:first-child{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      // height: ;
    }

    @media screen and (min-width : 500px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    @media screen and (min-width : $screen-md) {
      padding: $margin-sm $margin-md $margin-lg $margin-md;
    }

    .select-note{
      width: 100%;
      max-width: 500px;
      margin-bottom: $margin-sm;
      
      
      @media screen and (min-width : 500px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: $margin-md;
      }

      h2{
        display: none;
        font-size: 40px;
        margin-bottom: 50px;
        @media screen and (min-width : $screen-md) {
          display: block;
        }
      }

      p{
        font-family: 'Montserrat-Bold';
        color : $title-color;
        font-size: $medium;
      }

      .star{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 45px;
        &:not(:last-child){
          margin-right: $margin-xs;
        }
        &.disabled{
          opacity: 0.3;
        }
      }
    }

    .container-button{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .link-home{
      padding: $margin-md 0;
  
      >a{
        display: flex;
        align-items: center;
        text-decoration: none;
        p{
          font-family: 'Montserrat-Bold';
          font-size: $medium;
          margin: 0 0 0 $margin-sm;
          color: $text-color;
        }
      }
    }

  }

}

