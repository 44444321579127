@import "../../styles/variables.scss";

.modal{
  position: fixed;
  z-index: 1000;
  top:47%;
  left:50%;
  transform: translate(-50%,-50%);
  max-width: 500px;
  width: calc(100% - 40px);
  border-radius: 8px;
  background-color: $white;
  box-sizing: border-box;
  padding: $margin-md;

  h3{
   text-align: center;
  }

  form{
    .field{
      margin-bottom: $margin-sm;
    }

    .date{
      display: flex;
      justify-content: space-between;
      margin-bottom: $margin-md;
      >div{
        width: calc(50% - 10px);
      }
    }
    .errors{
      font-family: "MontSerrat-Bold";
      margin: 0 0 $margin-md 0;
      color: #DB4437;
    }
  }
}