.container-input-email{

  label{
    font-size: 14px;
  }

  &.inline{
    display: flex;
    justify-content: space-between;
    align-items: center;
    label{
      margin-right: 10px;
    }
  }

  &:not(.inline){
    label+.input-email{
      margin-top: 10px;
    }
  }

  .input-email{
    display: block;
    padding: 10px;
    border: none;
    outline: none;
    background-color: white;
    border-radius: 8px;
    width: 100%;
    min-width: 250px;
    box-sizing: border-box;
    font-size: 12px;
    border: solid 1px rgba(230,230,230,1);

    // &:focus{
    //   box-shadow: 0 0 0 1px #009973;
    // }

    &::placeholder {
      font-size: 12px;
      color : #8c8c8c;
    }
  }
}
