@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.input-file{
  position: relative;
  background: rgba(130, 170, 228, 0.1);
  border: 1px dashed #00A8E8;
  box-sizing: border-box;
  border-radius: 14px;
  min-height: 170px;
  cursor: pointer;
  font-size: 0;

  &.width-picture{
    border: none;
  }


  .button{
    position: absolute;
    z-index: 2;
    top:0;
    right:0;
    transform: translate(35%,-35%);
    background-color: $light-blue;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    color: $white;
    cursor: pointer;
  }

  .instructions{
    position: absolute;
    z-index: 0;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);

    display: flex;
    align-items: center;
    justify-content: center;

    p{
      font-family: "MontSerrat-Bold";
      font-size: $regular;
      color: #63808E;
      margin-bottom: $margin-xs;
      &.sub{
        font-family: "MontSerrat-Regular";
      }
    }

    .icon{
      color: #63808E;
      margin-right: $margin-sm;
      svg{
        transform: rotate(-30deg);
      }
    }
  }

  img{
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 14px;
  }

}
