@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.configuration{
  position: relative;
  height: 100%;
  max-height: 100%;
  z-index: 2;
  box-sizing: border-box;
  overflow-y: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .header-desktop {
    display: none;
    @media screen and (min-width: $screen-md) {
      display: flex;
      justify-content: flex-start;
      margin: $margin-sm $margin-md $margin-md $margin-md;
      svg {
        position: relative;
        top: 4px;
        margin-right: $margin-sm;
        color: $light-blue;
      }
    }
  }

  .container{
    max-width: 1200px;
    margin : 0 auto;
    box-sizing: border-box;
    padding: $margin-sm $margin-md $margin-sm $margin-md;

  }

  .container .advices{
    margin-bottom: $margin-md;
  }
  
  .container .form{
    .form-col:first-child section{
      margin-bottom: $margin-md;
      button{
        font-family: 'MontSerrat-SemiBold';
        min-width: 180px;
      }
      @media screen and (min-width: $screen-sm) {
        margin-bottom: 0;
      }
    }
    
    @media screen and (min-width: $screen-md) {
      display: flex;
      flex: 100%;
      justify-content: space-between;
      .form-col{
        flex: 1;
        max-width: calc(50% - 20px);
      }
    }
    section{
      .title-section{
        display: flex;
        justify-content: space-between;
        margin-bottom: $margin-md;
  
        h3{
          font-size: $medium;
          margin: 0
        }
        p{
          font-size: $regular;
          color: $text-grey;
          margin: 0
        }
      }

      .event{
        position: relative;
        box-shadow: 0px 30px 60px rgba(57, 57, 57, 0.1);
        border-radius: 8px;
        margin-bottom: $margin-md;
        padding: $margin-md;

        .next-event{
          
          .edit{
            position: absolute;
            top:0;
            right:0;
            transform: translate(35%,-35%);
            cursor: pointer;
          }

          .date{
            @include tag;
            font-size: $regular;
            margin-bottom: $margin-md;
            .separator{
              position: relative;
              top:-1px;
              color: $light-blue;
              opacity: .5;
            }
          }

          p{
            font-family: "MontSerrat-Bold";
            font-size: $medium;
            color: $text-color;
            margin-bottom: 0;
          }
        }

        .add-event p{
          text-align: center;
        }
      }
  
      .container-picture{
        position: relative;
        margin-bottom: $margin-md;
        .loader{
          position: absolute;
          z-index: 2;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }
  
      .container-message{
        margin-bottom: $margin-md;
      }
  
      .container-prizes{
        margin-bottom: $margin-md;
    
        >div:not(:last-child){
          margin-bottom: $margin-sm;
        }
      }
  
    }
  }




}

