@import '../../../../styles/variables.scss';

.button{
  display: inline-flex;
  height: 37px;
  width: 37px;
  justify-content: center;
  align-items: center;
  background-color: $white;
  margin-right: $margin-sm;
  border-radius: 8px;
  cursor: pointer;
  &.active{
    color : $white;
    background: linear-gradient(180deg, #00A8E8 0%, #0081C9 100%);
  }
}