@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.containerCard {
  height: 100%;
  max-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 300px;
  box-sizing: border-box;
  overflow: hidden;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 300px;
  height: 470px;
  max-height: calc(100% - 120px);
  // perspective: 1200px;
  background-color: transparent;
  cursor: pointer;
  // pointer-events: none;
  touch-action: pan-y;

  @include remove-tap-highlight;

  .container {
    position: relative;
    width: 100%;
    height: 100%;
    transition: all 0.8s;
    transform-style: preserve-3d;
    border-radius: 15px;
    overflow: hidden;
    will-change: transform, opacity;

    &.disabled {
      opacity: 0.5;
      transform: scale(0.9);
    }
  }

  &.reverse {
    .front {
      transform: rotateY(180deg);
      pointer-events: none;
    }
    .back {
      transform: rotateY(0deg);
    }
  }

  &:not(.reverse) {
    .back {
      pointer-events: none;
    }
  }

  .front,
  .back {
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 15px;
    padding: $margin-sm;
    box-sizing: border-box;
    transition: transform 0.8s;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    box-shadow: 0px 4px 20px 2px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
  }

  .front {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $white;

    .header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin: 0 0 $margin-md 0;

      p {
        margin: 0;
        font-size: $small;
        line-height: $small;
      }

      .sub-thematic,
      .point {
        padding: $margin-sm;
        box-sizing: border-box;
        height: 30px;
        border-radius: 15px;
      }

      .container-left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        .sub-thematic {
          font-family: "MontSerrat-Bold";
          text-transform: uppercase;
          margin-right: 10px;

          @include colorSerie(colorAndBackground);
        }

        .difficulty {
          color: $text-grey;
          margin-right: $margin-md;
          white-space: nowrap;
          padding: 8px 0 8px 3px;
        }
      }

      .container-right {
        display: flex;
        align-items: center;
        justify-content: center;

        .point {
          @for $i from 1 through 10 {
            $serie: serie-#{$i};
            &.serie-#{$i} {
              color: getColor($serie, 1);
              box-shadow: 0px 4px 20px 2px getColor($serie, 0.3);
            }
          }
        }
      }
    }

    .question {
      font-family: "MontSerrat-Bold";
      font-size: $medium;
      line-height: 19px;
      color: $blue;
      margin: 0 0 $margin-md 0;
    }

    .container-media {
      position: relative;
      margin-bottom: $margin-md;

      .media-image {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 200px;
        width: 100%;
        overflow: hidden;
        button {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        > img {
          object-fit: contain;
          height: 100%;
          width: 100%;
        }
      }

      button.stop-image,
      button.stop-video {
        font-size: $small;
        padding: 5px $margin-sm;
        white-space: nowrap;
        margin-top: $margin-sm;
      }

      .media-controls {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          margin: 0 $margin-sm;
        }
      }

      iframe {
        height: 100% !important;
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;

      img:first-child {
        margin-left: $margin-sm;
      }

      p {
        color: $text-grey;
        margin: 0 0 0 $margin-sm;
        text-align: center;
      }
      > span {
        display: flex;
        align-items: center;
        justify-content: space-between;
        img:first-child {
          margin-right: $margin-sm;
        }
      }
    }

    .container-indice {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      overflow: hidden;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        text-align: center;
        padding: $margin-lg;
        justify-content: center;
        width: 100%;
        overflow: hidden;
        p {
          font-family: "MontSerrat-Bold";
          margin-bottom: 0;
        }
      }

      > p:last-child {
        color: rgba(47, 48, 78, 0.5);
        margin: 0 0 0 10px;
        text-align: center;
      }

      img {
        margin-bottom: $margin-xs;
      }

      h4 {
        text-transform: uppercase;
        font-family: "MontSerrat-Bold";
        font-size: $regular;
        margin: 0 0 $margin-sm 0;

        @include colorSerie(color);
      }

      .indice,
      .answer {
        font-family: "MontSerrat-Regular";
        font-size: $medium;
        color: $text-color;
        text-align: center;
      }
    }
  }

  .back {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    background-color: $blue;
    transform: rotateY(180deg);
    height: 100%;

    h4,
    p {
      font-size: $regular;
      margin: 0 0 8px 0;
    }

    .container-answer {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      align-items: center;
      text-align: center;
      > div {
        display: flex;
        max-height: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
    }

    .container-detail {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: $margin-lg;
      &.detail-indice {
        flex: 1;
        > div {
          display: flex;
          flex: 1;
          max-height: 100%;
          width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
      }
    }

    .title,
    .title-detail,
    .answer {
      font-family: "MontSerrat-Bold";
    }

    .title {
      font-size: $small;
      text-transform: uppercase;
      color: $white;
    }

    .title-detail {
      opacity: 0.5;
      color: $white;
    }

    .answer {
      opacity: 0.5;
      color: $white;
    }

    p {
      color: $white;
    }

    > p:last-child {
      opacity: 0.5;
      color: $white;
    }
  }
}
