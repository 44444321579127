@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.result-teams{
  position: relative;
  max-height: 100%;
  overflow-y: auto;
  z-index: 2;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .header{
    padding: $margin-md $margin-md 0 $margin-md;

    // @media screen and (min-width: $screen-sm) {
    //   padding: $margin-md $margin-lg 0 $margin-lg;
    // }

    .advices{
      margin-bottom: $margin-md;
    }

    h2{
      font-size: $medium;
      color: $light-blue;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    .container-button{
      display: flex;
      justify-content: space-between;

      .btnSort{
        display: flex;
        flex-direction: column;
        font-size: 20px;
        svg:first-child{
          margin-bottom: -3px;
        }
      }
    }

    .anchor {
      font-family: "Montserrat-Bold";
      font-size: $medium;
      color: $light-blue;
      text-decoration: underline;
      margin: $margin-md 0 $margin-sm 0;
      cursor: pointer;
    }

    // input{
    //   @include basic-input;
    //   color: $text-color;
    //   background-color: rgba(130, 170, 228, 0.1);
    //   border: 1px solid rgba(47, 48, 78, 0.06);

    //   @media screen and (min-width: $screen-sm) {
    //     display: block;
    //     max-width: 660px;
    //     margin: 0 auto 0 auto;
    //   }

    //   &::placeholder{
    //     color: $title-color;
    //   }
    // }

  }

  .container{
    width: 100%;
    height: 100%;
    margin : 0 auto;
    box-sizing: border-box;

    @media screen and (min-width: $screen-sm) {
      max-width: 700px;
    }

  }

  .containerForm {
    border-top: 1px dashed rgba(32, 32, 32, 0.1);
    margin: $margin-md 0 0 0;
    padding: $margin-md $margin-sm 0 $margin-sm;
    p {
      color: $light-blue;
      padding: 0 $margin-sm;
    }
  }

}