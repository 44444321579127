@import "../../../styles/variables.scss";

.modal-camera{
  position: fixed;
  z-index: 1000;
  top:47%;
  left:50%;
  transform: translate(-50%,-50%);
  max-width: 500px;
  width: calc(100% - 40px);
  border-radius: 8px;

  .preview{
    position: relative;
    max-width: 820px;
    width: 100%;
    padding-top: 63.4%;

    .loader{
      position: absolute;
      z-index: 10;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
    }

    img{
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }



  .container-button{
    position: absolute;
    bottom: -100px;
    left:50%;
    transform: translate(-50%,-50%);

    display: flex;

    button{
      outline: none;
      background: transparent;
      border: 1px solid $white;
      box-shadow: none;
      margin: 0 $margin-xs;
    }
  }



}

.item-ranking{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  &:not(:last-child){
    margin-bottom: $margin-md;
  }

  .index{
    width: 24px;
    font-family: 'MontSerrat-Bold';
    font-size: $regular;
    margin: 0;
    color : $text-color;
  }
  .container{
    display: flex;
    flex: 1;
    max-width: calc(100% - 24px);
    justify-content: space-between;
    align-items: center;
    background: $white;
    box-shadow: 0px 30px 60px rgba(57, 57, 57, 0.1);
    border-radius: 8px;

    box-sizing: border-box;

    &.no-picture{
      padding: $margin-sm $margin-xs $margin-sm $margin-sm;
    }

    &:not(.no-picture){
      padding: $margin-xs $margin-xs $margin-xs $margin-sm;
    }

    p{
      font-family: 'MontSerrat-Bold';
      font-size: $regular;
      margin: 0;
      color : $text-color;
    }

    >div:first-child{
      display: block;
      max-width: calc(100% - 117px);
      box-sizing: border-box;
    }

    .name{
      max-width: 100%;
      width: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .point{
      font-family: 'MontSerrat-Regular';
      font-size: $medium;
      color: $text-grey;
      margin-top: $margin-xs;
      span{
        font-family: 'MontSerrat-Bold';
        font-size: $subtitle;
        color: $light-blue;
      }
    }

    .input-file{
      position: relative;
      z-index: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      height: 52px;
      width: 82px;
      min-width: 82px;
      .icon{
        position: relative;
        top:3px;
      }
    }

    .picture{
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 168, 232, 0.15);
      border-radius: 8px;
      height: 74px;
      width: 117px;
      min-width: 117px;
      font-size: 27px;
      color : #4B4C76;
      cursor: pointer;
      overflow: hidden;
      .loader{
        position: absolute;
        z-index: 2;
      }

      img{
        position: absolute;
        z-index: 1;
        object-fit: contain;
        max-width: 100%;
        pointer-events: none;
      }
    }

  }
}