@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.container {
  position: relative;
  z-index: 2;
  .form {
    background-color: $white;
    padding: $margin-sm;
    border-radius: 8px;
    margin: 0 0 $margin-md 0;
    &:not(.noShadow){
      box-shadow: 0px 30px 60px rgba(57, 57, 57, 0.1);
    }

    .team {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: $margin-sm;
      > div:first-child {
        width: 100%;
      }

      .icon {
        position: relative;
        height: 42px;
        width: 42px;
        margin-left: $margin-sm;
        cu svg,
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-width: 32px;
          pointer-events: none;
        }
      }
    }
  }

  .email {
    margin-bottom: $margin-sm;
    input {
      font-family: "MontSerrat-Bold";
      font-weight: normal;
      font-size: $regular;
      background: rgba(130, 170, 228, 0.1) !important;
      border: 1px solid rgba(47, 48, 78, 0.06) !important;
      color: $text-grey !important;
      height: 42px;
      border-radius: 12px;

      // &:-internal-autofill-selected,
      &::-webkit-autofill,
      &::-webkit-autofill:hover,
      &::-webkit-autofill:focus {
        color: $text-grey !important;
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
      &::placeholder {
        font-family: "MontSerrat-Regular" !important;
        color: $text-grey !important;
        font-size: $regular !important;
      }
    }
  }

  .score {
    display: inline-flex;
    width: 65px;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 12px;
    border: 1px solid;
    padding: 0 $margin-sm;
    color: $text-grey;
    margin-left: $margin-sm;

    @for $i from 1 through 10 {
      $serie: serie-#{$i};
      &.serie-#{$i} {
        border-color: getColor($serie, 1) !important;
        background: getColor($serie, 0.3) !important;
      }
    }

    &.total {
      border-color: $light-blue;
      background: rgba(0, 168, 232, 0.1);
    }

    input {
      display: inline-block;
      text-align: right;
      border: none;
      font-family: "MontSerrat-Bold";
      font-weight: normal;
      font-size: $regular;
      color: $text-color;
      padding: 0 !important;
      background: transparent;
      width: 100%;
      min-width: 32px;
      margin-right: 5px;
      -moz-appearance: textfield;
      outline: none;
      border: none !important;
      background: transparent !important;
      margin: 0 5px 0 0 !important;
      border-radius: 0px !important;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  button {
    min-width: 270px;
  }
}
