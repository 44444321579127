@import "../../styles/variables.scss";

.modal-bonus{
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0;
  $offsetX : calc(-100% + 33px);
  transform: translate($offsetX,0);
  li{
    display: inline-flex;
    height: 44px;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    list-style: none;
    cursor: pointer;

    .icon{
      display: inline-block;
      cursor: pointer;
      svg{
        width: 25px;
        height: 25px;
      }
      img{
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }

    &:not(:last-child){
      margin-bottom: $margin-sm;
    }

    &:not(.close){
      font-family: "MontSerrat-Regular";
      font-size: $regular;
      border-radius: 7px;
      background: white;
      padding: $margin-sm;
    }

    .loader{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
      border-radius: 17px;
      background: linear-gradient(180deg, #00A8E8 0%, #0081C9 100%);
    }

    &.close img{
      width: 34px;
      height: 34px;
    }
  }
}