.overlay-modal{
  position: fixed;
  z-index: 999;
  top:0;
  height: 100%;
  width: 100%;
  background-color: rgba(26, 27, 66, .6);
}

.modal{
  position: fixed;
  z-index: 1000;
  // top:50%;
  // left:50%;
  // transform: translate(-50%,-50%);
  // height: 300px;
  // width: 300px;
  // padding: 20px;
  // border-radius: 8px;
  // background-color: white;
}
