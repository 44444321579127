@import "../../styles/variables.scss";

.modal{
  position: fixed;
  z-index: 1000;
  top:47%;
  left:50%;
  transform: translate(-50%,-50%);
  max-width: 500px;
  width: calc(100% - 40px);
  border-radius: 8px;

  .container-image{
    img{
      display: block;
      margin : 0 auto;
      width: 100%;
      max-height: 300px;
      object-fit: contain;
    }
  }

  .videoAudio{
    position: relative;
    height: 40px;
    overflow: hidden;
    iframe{
      position: absolute;
      bottom: 0;
    }
  }

  button{
    position: absolute;
    outline: none;
    background: transparent;
    border: 1px solid $white;
    bottom: -100px;
    left:50%;
    transform: translate(-50%,-50%);
    box-shadow: none;
  }

  // >div{
  //   width: 100% !important;
  // }

  // iframe{
  //   height: inherit !important;
  // }
}