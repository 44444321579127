@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.scores{
  width: 100%;
  height: 100%;
  overflow-y: auto;
  max-width: 870px;
  margin: 0 auto;
  padding-bottom: $margin-md;
  box-sizing: border-box;
  scrollbar-width: none;
  touch-action: pan-y;

  // width: 100%;
  // box-sizing: border-box;
  // max-width: 870px;
  // margin: 0 auto;

  &::-webkit-scrollbar {
    display: none;
  }
  
  .anchor {
    font-family: "Montserrat-Bold";
    font-size: $medium;
    color: $white;
    padding: 0 $margin-md 0 $margin-md;
    text-decoration: underline;
    margin-top: $margin-md;
    cursor: pointer;
  }

  .advices{
    padding-top: $margin-sm;
  }

  .container{
    padding: 0 $margin-md;
    // input{
    //   @include basic-input;
    // }
  }

  .formAddTeam{
    border-top: 1px dashed rgba(32, 32, 32, 0.1);
    padding-top: $margin-md;
    margin: $margin-md $margin-md 0 $margin-md;
  }

  .link-next {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: linear-gradient(280.72deg, #2f304e 0%, #3b3d74 102.83%);
    box-shadow: 0px 9px 17px rgba(50, 51, 87, 0.43);
    border-radius: 14px;
    padding: $margin-md;
    box-sizing: border-box;
    text-decoration: none;
    cursor: pointer;

    > div > span {
      display: block;
      font-family: "MontSerrat-Bold";
      text-transform: uppercase;
      &:first-child {
        color: rgba(255, 255, 255, 0.5);
        font-size: $small;
        margin-bottom: $margin-xs;
      }
      &:last-child {
        font-size: $regular;
      }
    }

    .arrow {
      transform: rotate(180deg);
    }
  }

  .link-home{
    margin-top: $margin-lg;
    color: $white;
    >a{
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $white;
      p{
        font-family: 'Montserrat-Bold';
        font-size: $medium;
        margin: 0 0 0 $margin-sm;
        color: $white;
      }
    }
  }

}