@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.modal-reload{
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;

  .loader{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    border-radius: 17px;
    background: linear-gradient(180deg, #00A8E8 0%, #0081C9 100%);
  }

  li{
    display: inline-block;
    list-style: none;
    cursor: pointer;

    &:not(:last-child){
      margin-bottom: $margin-sm;
    }

    &:not(.close){
      font-family: "MontSerrat-Regular";
      font-size: $regular;
      border-radius: 7px;
      background: white;
      padding: $margin-sm;
    }

    &.close img{
      width: 34px;
      height: 34px;
    }
  }
}