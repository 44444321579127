@import "../../styles/variables.scss";

.teams{
  position: relative;
  height: 100%;
  z-index: 2;
  box-sizing: border-box; 
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 1200px;
    width: 100%;
    margin : 0 auto;
    box-sizing: border-box;

    .container-advices{
      padding: $margin-sm $margin-md $margin-sm $margin-sm;
    }

    .anchor {
      font-family: "Montserrat-Bold";
      font-size: $medium;
      color: $light-blue;
      text-decoration: underline;
      margin-bottom: 0;
      cursor: pointer;
      padding: $margin-sm $margin-md $margin-sm $margin-sm;
    }

    // add padding to the last item of teams list
    >div>ul:last-child>li:last-child{
     padding-bottom: $margin-lg;
    }

    // reduce alphabet size below 700px
    @media screen and (max-height: 700px) {
      >div>ul:first-child>li{
        font-size: 10px;
      }
    }

    .containerForm {
      border-top: 1px dashed rgba(32, 32, 32, 0.1);
      margin: $margin-md 0 0 0;
      padding: $margin-md $margin-sm 0 $margin-sm;
      p {
        color: $light-blue;
        padding: 0 $margin-sm;
      }
    }

  }
}