@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.result-teams{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
  z-index: 2;
  scrollbar-width: none;

  &.disabled {
    opacity: 0.5;
    transform: scale(0.9);
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .header{
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: $margin-md $margin-md 0 $margin-md;

    @media screen and (min-width: $screen-sm) {
      padding: $margin-md $margin-md 0 $margin-md;
    }

    .advices{
      margin-bottom: $margin-sm;
    }
  }

  .container-filters{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 $margin-md 0 $margin-md;

    @media screen and (min-width: $screen-sm) {
      padding: 0 $margin-md 0 $margin-md;
    }
  
    .btnSort{
      display: flex;
      flex-direction: column;
      font-size: 20px;
      svg:first-child{
        margin-bottom: -3px;
      }
    }

    .anchor {
      font-family: "Montserrat-Bold";
      font-size: $medium;
      color: $light-blue;
      text-decoration: underline;
      margin-bottom: 0;
      cursor: pointer;
    }

  }

  .container-filters,
  .container{
    width: 100%;
    margin : 0 auto;
    box-sizing: border-box;

    @media screen and (min-width: $screen-sm) {
      max-width: 700px;
    }

  }

  .container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    >:first-child{
      flex: 1;
    }
  }

  .containerForm {
    border-top: 1px dashed rgba(32, 32, 32, 0.1);
    margin: $margin-md $margin-md 0 $margin-md;
    padding: $margin-md 0 0 0;
    p {
      color: $light-blue;
      padding: 0 $margin-sm;
    }
  }

  .containerForm+.link-home{
    margin-top: 0px;
  }

  .link-home{
    margin-top: 40px;
    padding: 0 $margin-md $margin-md $margin-md;

    >a{
      display: flex;
      align-items: center;
      text-decoration: none;
      svg {
        position: relative;
        top: -1px;
        color: $text-color;
      }
      p{
        font-family: 'Montserrat-Bold';
        font-size: $medium;
        margin: 0 0 0 $margin-sm;
        color: $text-color;
      }
    }
  }

}