@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.correction{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(100% + 60px);
  height: 100%;
  min-width: 300px;
  box-sizing: border-box;
  scrollbar-width: none;
  padding: $margin-lg 30px 0 30px;
  margin: 0 -30px;
  transition: all 0.8s;
  transform-style: preserve-3d;
  will-change: transform, opacity;
  overflow: hidden;

  >div{
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &.disabled {
    opacity: 0.5;
    transform: scale(0.9) translateY(7%);
  }

  &::-webkit-scrollbar {
    display: none;
  }

}