@import '../../styles/variables.scss';

.topbar{
  display: block;
  position: relative;
  z-index: 4;
  border-radius: 0 0 26px 26px;
  width: 100%;
  background-color: $blue;
  padding: $margin-md;
  box-sizing: border-box;
  transition: height .6s ease-in-out;

  @media screen and (min-width : $screen-md) {
    display: none;
  }

  @media screen and (min-width: $screen-sm) {
    padding: $margin-md $margin-lg $margin-md $margin-lg;
  }

  .container{
    .container-logo{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: $margin-sm;
      img:first-child{
        position: relative;
        top:2px;
        margin-right: $margin-sm;
        height: 18px;
        width: 18px;
      }

      img:last-child{
        margin-right: 5px;
        height: 30px;
      }
    }

    .container-title{
      display: flex;
      align-items: center;
      justify-content: space-between;
      color : $white;
      width:100%;
      
      &.feedback h2{
        color: $light-blue;
        font-size: $medium;
        text-transform: uppercase;
      }

      >span{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-right: $margin-md;
        box-sizing: border-box;
        img{
          margin-right: $margin-xs;
        }
      }

      svg{
        position: relative;
        top: 2px;
        margin-right: 2px;
      }

      h1,h2{
        font-family: "Montserrat-Bold";
        color : $white;
        margin: 0 0 0 $margin-sm;
      }
    
    
      h1{
        font-size: $title;
      }
    
      h2{
        font-size: 18px;
      }

      .loader{
        margin-left: $margin-xs;
      }

      a{
        font-family: 'Montserrat-SemiBold';
        font-size: $regular;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        text-decoration: none;
        img{
          margin-right: $margin-sm;
        }
      }
      
    }

  }

  .header-info-quiz{
    display: flex;
    align-items: center;
    width:100%;

    .info{
      flex: 1;
    }

    .back{
      display: inline-block;
      width: 37px;
      color: $white;
    }

    h2{
      color: $light-blue;
      font-size: $medium;
      text-transform: uppercase;
    }
    p{
      color: $white;
    }
  }
  

}