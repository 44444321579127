@import "./variables.scss";
@import "./mixins.scss";

html,
body {
  height: 100%;
}

h1,
h2,
h3,
h4 {
  font-family: "MontSerrat-Bold";
  font-weight: normal;
  margin-top: 0;
  margin-bottom: $margin-sm;
  color: $title-color;
}

p {
  font-family: "Montserrat-Regular";
  font-size: $regular;
  line-height: 18px;
  color: $text-color;
  margin-top: 0;
  margin-bottom: $margin-sm;
  &.disabled {
    color: $text-grey;
  }
}

button:not(.MuiButtonBase-root):not(.PrivatePickersYear-yearButton) {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "MontSerrat-Bold";
  font-size: $medium;
  font-weight: normal;
  text-transform: uppercase;
  height: 46px;
  border-radius: 13px;
  text-align: center;
  border: none;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  background: linear-gradient(280.72deg, #2f304e 0%, #3b3d74 102.83%);
  box-shadow: 0px 9px 17px #3738675e;
  color: $white;
  padding: 0 $margin-md;
  pointer-events: cursor;
  @include remove-tap-highlight();

  >div{
    margin-left: $margin-sm;
  }

  &.secondary{
    background: linear-gradient(180deg, #00A8E8 0%, #0081C9 100%);
    box-shadow: none;
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

.bold {
  font-family: "MontSerrat-Bold";
}

.app{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  
}

.app .global {
  position: relative;
  display: flex;
  flex: 1;
  align-self: flex-start;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;

  #root {
    position: relative;
    z-index: 1;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;

    &:before {
      content: "";
      position: fixed;
      z-index: 0;
      top: -5%;
      right: -20%;
      display: block;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(66, 187, 255, 0.2) 0%,
        rgba(66, 187, 255, 0) 100%
      );
      width: 60%;
      padding-top: 60%;
      border-radius: 50%;
      pointer-events: none;

      @media screen and (min-width: $screen-sm) {
        width: 50%;
        padding-top: 50%;
        border-radius: 50%;
        top: -40%;
        right: -20%;
      }
    }

    &:after {
      content: "";
      position: fixed;
      z-index: 0;
      bottom: 10%;
      left: -45%;
      display: block;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(145, 4, 255, 0.43) 0%,
        rgba(145, 4, 255, 0) 100%
      );
      opacity: 0.3;
      width: 70%;
      padding-top: 70%;
      border-radius: 50%;
      pointer-events: none;

      @media screen and (min-width: $screen-sm) {
        width: 50%;
        padding-top: 50%;
        border-radius: 50%;
        bottom: -10%;
        left: -25%;
      }
    }
  }
}

#sidebar {
  height: 100%;
}

.only-on-tablet{
  display: block;
  @media screen and (min-width: $screen-md) {
    display: none;
  }
}

.only-on-desktop{
  display: none;
  @media screen and (min-width: $screen-md) {
    display: block;
  }
}

.center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-camera{

  #white-flash{
    pointer-events: none;
  }

  .react-html5-camera-photo{
    position: relative;
    max-width: 820px;
    width: 100%;
    padding-top: 63.4%;
    >video{
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .react-html5-camera-photo video,
  .react-html5-camera-photo img{
    border-radius: 8px;
    overflow: hidden;
  }

  #container-circles{
    bottom: -20px !important;
  }
}

.DraftEditor-root{
  .DraftEditor-editorContainer>div{
    min-height: 90px;
  }
}

