@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.quiz {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  overscroll-behavior: contain;
  box-sizing: border-box;

  .advices {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: $margin-md;

    ul li,
    p {
      color: $white;
    }
    border: 1px solid;
    border-color: $white;
    background: rgba(255, 255, 255, 0.07);
    ul {
      padding: 0 0 0 $margin-sm;
      li {
        list-style: disc;
        padding-left: 0px;
        margin-left: $margin-sm;
      }
    }
  }

  .sidebar {
    z-index: 2;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    padding: $margin-md;

    @media screen and (max-width: $screen-md) {
      display: none;
    }
    position: relative;
    transition: all 0.6s ease-in-out;

    &.closed .leave {
      opacity: 0;
      transform: translate(-100px, 0);
    }

    &:not(.closed) .response {
      opacity: 0;
    }

    .response {
      position: absolute;
      z-index: 3;
      top: 50%;
      left: -75px;
      font-family: "MontSerrat-Bold";
      font-size: 18px;
      white-space: nowrap;
      color: $white;
      transform-origin: center center;
      transform: rotate(-90deg);
      margin: 0;
      transition: all 0.4s ease-in-out;
    }

    .leave {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: $margin-md;
      width: 100%;
      transition: all 0.6s ease-in-out;

      > a {
        display: flex;
        align-items: center;
        text-decoration: none !important;
      }

      p {
        color: $white;
        margin: 0 0 0 $margin-sm;
        white-space: nowrap;
      }
    }

    .toggle {
      position: absolute;
      z-index: 2;
      right: -21px;
      top: 50%;
      transform: translate(0, -50%);
      color: $white;
      cursor: pointer;
      svg {
        position: absolute;
        top: 44px;
        left: 8px;
        transform: translate(0, -50%);
      }
    }

    @media screen and (min-width: $screen-md) {
      height: 100%;
      background: $blue;
      &:not(.closed) {
        max-width: 393px;
      }

      &.closed {
        max-width: 50px;
        .container-quickview {
          opacity: 0;
          transform: translate(-300px, 0);
        }
      }

      .container-quickview {
        height: 100%;
        min-width: 353px;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: none;
        transition: all 0.6s ease-in-out;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  &:not(.fullwidth) .container-quiz {
    @media screen and (min-width: $screen-md) {
      width: calc(100% - 393px);
    }
  }

  &.fullwidth .container-quiz {
    @media screen and (min-width: $screen-md) {
      width: calc(100% - 50px);
    }
  }

  .container-quiz {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    transition: all 0.6s ease-in-out;

    @include colorSerie(backgroundGradient);

    &.serie-end {
      background-color: #edf4f7;
    }

    .help {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 300px;
      overflow: hidden;

      height: 100%;
      max-height: 100%;

      box-sizing: border-box;
      > div {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 300px;
        height: 470px;
        max-height: calc(100% - 120px);
        box-sizing: border-box;

        .help-advice{
          flex: 1;
          overflow-y: auto;
        }

        .advices {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          max-height: 100%;
          overflow: auto;
          scrollbar-width: none;
          margin-bottom: 0;
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }

      .iconSwipe {
        transform: scale(-1, 1);
      }

      > div .with-advices {
        display: flex;
        width: 100%;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: $margin-md;

        @media screen and (max-height: 675px) {
          padding-top: 10px;
        }

        img {
          width: 60px;
          margin-right: 5px;
        }
        h4 {
          font-family: "MontSerrat-Bold";
          color: $white;
          font-size: $medium;
          text-align: left;
          width: 100%;
          margin-bottom: 0;
        }
      }

      > div .without-advices {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        h4 {
          font-family: "MontSerrat-Bold";
          font-size: $title;
          color: $white;
          text-align: center;
        }
      }
    }

    .logout {
      position: absolute;
      z-index: 3;
      bottom: $margin-md;
      left: $margin-md;
      transition: transform 0.2s ease-out;
      color: $white;

      &.hide {
        transform: scale(0);
      }

      @media screen and (min-width: $screen-md) {
        display: none;
      }
    }
  }
}
