@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

// MUI MOBILE DATEPICKER
.toolbar{
  p{
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    color: white;
  }
}

.container-input{

  label {
    @include label;
  }

  input{
    @include input;
  }


}
