@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.container{
  margin: 0;

  .options{
    background: rgba(130, 170, 228, 0.1);
    border: 1px solid rgba(47, 48, 78, 0.06);
    box-sizing: border-box;
    border-radius: 0px 0px 14px 14px;
    padding: $margin-sm;

    &.flex{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .maxLength{
        @include label();
        font-size: $small;
        margin: 0;
      }
    }


  }

  .container-text{
    @include textarea();
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}